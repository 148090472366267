@font-face {
  font-family: 'DancingScript';
  src: local('DancingScript'), url(./fonts/DancingScript-Regular.ttf) format('truetype');
}

body {
  background-color: #f0f0f0;
}

.fundo {
  background-color: #f0f0f0;
}

.container-nobars {
  padding: 0px;
  margin: 0px;
  max-width: 100% !important;
}

.itens-centered {
  float: none;
  margin: 0 auto;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.buttonBorder {
  border: 1px solid slategray;
}

.react-bootstrap-table {
  overflow-x: auto;
  white-space: nowrap;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem;
}

/* DATAS OCUPADAS */
.react-datepicker__day--highlighted-custom-1 {
  border-radius: 0.3rem;
  color: #000000;
  border: solid;
  border-color: #802323;
}

.react-datepicker__day--highlighted-custom-1:hover {
  border-radius: 0.3rem;
  color: #000000;
  border: solid;
  border-color: #802323;
}

/* DATAS COM LEMBRETES */
.react-datepicker__day--highlighted-custom-2 {
  color: #ff0000;
}

.react-datepicker__day--highlighted-custom-2:hover {
  color: #ff0000;
}

/* DATAS OCUPADAS E COM LEMBRETES */
.react-datepicker__day--highlighted-custom-3 {
  border-radius: 0.3rem;
  color: #ff0000;
  border: solid;
  border-color: #802323;
}

.react-datepicker__day--highlighted-custom-3:hover {
  border-radius: 0.3rem;
  color: #ff0000;
  border: solid;
  border-color: #802323;
}

.table td,
.table th {
  vertical-align: middle;
}

.min-margin {
  padding-top: 10px;
  padding-bottom: 8px;
}

.contract-container {
  background-color: white;
  width: 210mm;
  min-height: 297mm;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

.contract-title {
  margin-top: 0cm;
  margin-right: 0px;
  margin-bottom: 0cm;
  margin-left: 0px;
  text-align: center;
  text-indent: 0cm;
  line-height: 107%;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid black;
}

.contract-table table {
  width: 100%;
}

.contract-table table tr {
  border: 1px solid black;
}

.contract-table table td {
  border: 1px solid black;
  padding-left: 8px;
}

.contract-p-justify {
  text-align: justify;
}

.contract-linebreak {
  height: 8px;
  width: 126%;
  margin-left: -92px;
  background-color: rgb(238, 238, 238);
}